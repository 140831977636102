<template>
  <div class="noScroll">
    <v-row align="center" justify="center">
      <v-col cols="11" md="8" style="text-align: justify">
        <h1>Reports for {{ userId }}</h1>
        <v-row align="center">
          <v-col class="d-flex" cols="12">
            <v-select
              v-model="report"
              :items="reportTypes"
              :label="$t('Select Reports')"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row style="text-align: center" v-if="comingSoon">
          <v-col cols="12">
            <p>{{ report }} will be available soon!</p>
          </v-col>
        </v-row>
        <!-- For Personal KPI and Dashboard -->
        <div v-if="personalKPI && isAgent">
          <h2>{{ dashboard ? 'Your Dashboard' : 'Your Personal KPI'}}</h2>
          <span>Name: {{agentKPI.agentDoc.name}}</span><br />
          <span>Phone Number: {{agentKPI.agentDoc.phoneNumber}}</span><br />
          <span>Total Property: {{agentKPI.totalProperty}}</span><br /><br />
          <PieChart :chartData="pieChartData" />
          <br />
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Total Value:</td>
                  <td style="text-align: right">${{ agentKPI.totalPrice | formatNumber }}</td>
                </tr>
                <tr>
                  <td>Total Actual Value:</td>
                  <td style="text-align: right">${{ agentKPI.totalActualPrice | formatNumber }}</td>
                </tr>
                <tr>
                  <td>Total Deposit Value:</td>
                  <td style="text-align: right">${{ agentKPI.totalDepositPrice | formatNumber }}</td>
                </tr>
                <tr>
                  <td>Total Commission Fee:</td>
                  <td style="text-align: right">${{ agentKPI.totalCommissionFee | formatNumber }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-if="personalKPI && !isAgent">
          <p style="text-align: center">You are not an agent.</p>
        </div>

        <!-- For Group KPI -->
        <div v-if="groupKPI && isAgentGroupMember">
          <div>
            <h2>Your KPI</h2>
            <BarChart :chartData="barChartData" />
            <br />
            <div v-if="currentUser">
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Name:</td>
                      <td style="text-align: right">{{ currentUser.agentDoc.name }}</td>
                    </tr>
                    <tr>
                      <td>Phone Number:</td>
                      <td style="text-align: right">{{ currentUser.agentDoc.phoneNumber }}</td>
                    </tr>
                    <tr>
                      <td>Total Property:</td>
                      <td style="text-align: right">{{ currentUser.agentDoc.totalProperty }}</td>
                    </tr>
                    <tr>
                      <td>Estimated Value:</td>
                      <td style="text-align: right">${{ currentUser.totalPrice | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td>Estimated Commission Fee:</td>
                      <td style="text-align: right">${{ currentUser.totalCommissionFee | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td>Estimated Succeed Commission Fee:</td>
                      <td style="text-align: right">${{ currentUser.succeedCommissionFee | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td>Estimated Share Commission Fee:</td>
                      <td style="text-align: right">${{ currentUser.shareCommissionFee | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td>Estimated Deposit price:</td>
                      <td style="text-align: right">${{ currentUser.totalDepositPrice | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td>Actual Value:</td>
                      <td style="text-align: right">${{ currentUser.totalActualPrice | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td>Actual Commission Fee:</td>
                      <td style="text-align: right">${{ currentUser.actualTotalCommissionFee | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td>Actual Succeed Commission Fee:</td>
                      <td style="text-align: right">${{ currentUser.actualSucceedCommissionFee | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td>Actual Share Commission Fee:</td>
                      <td style="text-align: right">${{ currentUser.actualShareCommissionFee | formatNumber }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <hr />
              <br />
            </div>
            <!-- Agent group Summary -->
            <h2>Agent Group Summary</h2>
            <BarChart :chartData="barChartSummaryData" />
            <br />
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Group Name:</td>
                    <td style="text-align: right">{{ agentGroupDoc.name }}</td>
                  </tr>
                  <tr>
                    <td>Group Number:</td>
                    <td style="text-align: right">{{ agentGroupDoc.groupNumber }}</td>
                  </tr>
                  <tr>
                    <td>Total Members:</td>
                    <td style="text-align: right">{{ agentMemberCount }}</td>
                  </tr>
                  <tr>
                    <td>Total Property:</td>
                    <td style="text-align: right">{{ agentGroupSummary.sumTotalProperty }}</td>
                  </tr>
                  <tr>
                    <td>Total Estimated Value:</td>
                    <td style="text-align: right">${{ agentGroupSummary.sumTotalPrice | formatNumber }}</td>
                  </tr>
                  <tr>
                    <td>Estimated Commission Fee:</td>
                    <td style="text-align: right">${{ agentGroupSummary.sumTotalCommissionFee | formatNumber }}</td>
                  </tr>
                  <tr>
                    <td>Estimated Succeed Commission Fee:</td>
                    <td style="text-align: right">${{ agentGroupSummary.sumSucceedCommissionFee | formatNumber }}</td>
                  </tr>
                  <tr>
                    <td>Estimated Share Commission Fee:</td>
                    <td style="text-align: right">${{ agentGroupSummary.sumShareCommissionFee | formatNumber }}</td>
                  </tr>
                  <tr>
                    <td>Estimated Deposit Value:</td>
                    <td style="text-align: right">${{ agentGroupSummary.sumTotalDepositPrice | formatNumber }}</td>
                  </tr>
                  <tr>
                    <td>Actual Value:</td>
                    <td style="text-align: right">${{ agentGroupSummary.sumTotalActualPrice | formatNumber }}</td>
                  </tr>
                  <tr>
                    <td>Actual Commission Fee:</td>
                    <td style="text-align: right">
                      ${{ agentGroupSummary.sumActualTotalCommissionFee | formatNumber }}
                    </td>
                  </tr>
                  <tr>
                    <td>Actual Succeed Commission Fee:</td>
                    <td style="text-align: right">
                      ${{ agentGroupSummary.sumActualSucceedCommissionFee | formatNumber }}
                    </td>
                  </tr>
                  <tr>
                    <td>Actual Share Commission Fee:</td>
                    <td style="text-align: right">
                      ${{ agentGroupSummary.sumActualShareCommissionFee | formatNumber }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <hr />
            <br />
          </div>
          <!-- Agent Group Members List -->
          <h2>Agent Group Members Details List</h2>
          <LineChartGenerator
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
          />
          <br />
          <v-simple-table dense>
            <template v-slot:default>
              <thead style="text-align: left">
                <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Total Property</th>
                  <th>Estimated Value</th>
                  <th>Estimated Commission Fee</th>
                  <th>Estimated Succeed Commission Fee</th>
                  <th>Estimated Share Commission Fee</th>
                  <th>Estimated Deposit price</th>
                  <th>Actual Value</th>
                  <th>Actual Commission Fee</th>
                  <th>Actual Succeed Commission Fee</th>
                  <th>Actual Share Commission Fee</th>
                </tr>
              </thead>
              <tbody style="text-align: center">
                <tr v-for="ag in agentGroupReports" :key="ag.userId">
                  <td>{{ ag.agentDoc.name }}</td>
                  <td>{{ ag.agentDoc.phoneNumber }}</td>
                  <td>{{ ag.agentDoc.totalProperty }}</td>
                  <td>${{ ag.totalPrice | formatNumber }}</td>
                  <td>${{ ag.totalCommissionFee | formatNumber }}</td>
                  <td>${{ ag.succeedCommissionFee | formatNumber }}</td>
                  <td>${{ ag.shareCommissionFee | formatNumber }}</td>
                  <td>${{ ag.totalDepositPrice | formatNumber }}</td>
                  <td>${{ ag.totalActualPrice | formatNumber }}</td>
                  <td>${{ ag.actualTotalCommissionFee | formatNumber }}</td>
                  <td>${{ ag.actualSucceedCommissionFee | formatNumber }}</td>
                  <td>${{ ag.actualShareCommissionFee | formatNumber }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <hr />
        </div>
        <div v-if="groupKPI && !isAgentGroupMember">
          <p style="text-align: center">You are not belong to any agent group.</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MainMixin from "@/mixins/mainMixin";
import _ from "lodash";
// import LineChart from "../../components/lineChart.vue"
// import { Bar } from "vue-chartjs/legacy";
import BarChart from "../../components/barChart.vue";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import { provider } from "../../service/provider";
import axios from "axios";
import PieChart from '../../components/pieChart.vue'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "webviewReports",
  mixins: [MainMixin],
  components: { BarChart, LineChartGenerator, PieChart },
  mounted() {
    this.$jQuery("body").off();
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  data() {
    return {
      report: "Dashboard",
      reportTypes: ["Dashboard", "Personal KPI", "Group KPI", "Financial"],
      comingSoon: false,
      dashboard: true,
      groupKPI: false,
      personalKPI: true,
      userKPI: {
        esitmatedEarn: 0,
        actualEarn: 0,
      },
      agentGroupReports: [],
      agentGroupDoc: {},
      agentMemberCount: 0,
      agentGroupReportsList: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Total Price",
            backgroundColor: "#a83232",
            data: [],
          },
          {
            label: "Total Commission Fee",
            backgroundColor: "#a6a832",
            data: [],
          },
          {
            label: "Succeed Commission Fee",
            backgroundColor: "#32a842",
            data: [],
          },
          {
            label: "Share Commission Fee",
            backgroundColor: "#3296a8",
            data: [],
          },
        ],
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      chartId: "line-chart",
      datasetIdKey: "label",
      width: 400,
      height: 400,
      cssClasses: "",
      styles: {},
      plugins: [],
      currentUser: {},
      barChartData: {
        labels: ["Your KPI"],
        datasets: [
          {
            label: "Estimated Earn",
            data: [],
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            borderColor: ["rgba(255, 99, 132, 1)"],
            borderWidth: 1,
          },
          {
            label: "Acutal Earn",
            data: [],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgba(75, 192, 192, 1)"],
            borderWidth: 1,
          },
        ],
      },
      barChartSummaryData: {
        labels: ["Group KPI"],
        datasets: [
          {
            label: "Estimated Value",
            data: [],
            backgroundColor: ["rgba(245, 39, 39, 0.2)"],
            borderColor: ["rgba(245, 39, 39, 1)"],
            borderWidth: 1,
          },
          {
            label: "Estimasted Commission Fee",
            data: [],
            backgroundColor: ["rgba(245, 148, 39, 0.2)"],
            borderColor: ["rgba(245, 148, 39, 1)"],
            borderWidth: 1,
          },
          {
            label: "Estimated Succeed Commission Fee",
            data: [],
            backgroundColor: ["rgba(245, 238, 39, 0.2)"],
            borderColor: ["rgba(245, 238, 39, 1)"],
            borderWidth: 1,
          },
          {
            label: "Estimasted Share Commission Fee",
            data: [],
            backgroundColor: ["rgba(170, 245, 39, 0.2)"],
            borderColor: ["rgba(170, 245, 39, 1)"],
            borderWidth: 1,
          },
          {
            label: "Estimated Deposit Value",
            data: [],
            backgroundColor: ["rgba(106, 245, 39, 0.2)"],
            borderColor: ["rgba(106, 245, 39, 1)"],
            borderWidth: 1,
          },
          {
            label: "Actual Value",
            data: [],
            backgroundColor: ["rgba(39, 245, 196, 0.2)"],
            borderColor: ["rgba(39, 245, 196, 1)"],
            borderWidth: 1,
          },
          {
            label: "Actual Commission Fee",
            data: [],
            backgroundColor: ["rgba(39, 153, 245, 0.2)"],
            borderColor: ["rgba(39, 153, 245, 1)"],
            borderWidth: 1,
          },
          {
            label: "Actual Succeed Commission Fee",
            data: [],
            backgroundColor: ["rgba(39, 50, 245, 0.2)"],
            borderColor: ["rgba(39, 50, 245, 1)"],
            borderWidth: 1,
          },
          {
            label: "Actual Share Commission Fee",
            data: [],
            backgroundColor: ["rgba(131, 39, 245, 0.2)"],
            borderColor: ["rgba(131, 39, 245, 1)"],
            borderWidth: 1,
          },
        ]
      },
      agentGroupSummary: {},
      agentKPI: {},
      pieChartData: {
          labels: ['Total Value', 'Total Actual Value', 'Total Deposit Value', 'Total Commission Fee'],
          datasets: [
            {
              backgroundColor: ["#482ff7", "#2d6cdf", "#46c3db", "#f3f169"],
              data: []
            }
          ]
        },
      isAgentGroupMember: true,
      isAgent: true
    };
  },
  methods: {
    fetchAgentGroupReportsbyId: _.debounce(function (userId) {
      let vm = this;
      // vm.fetchAgentGroupReportsbyId({ userId: userId });
      let url =
        provider.baseURL + `/agentGroup/byId?userId=${userId}&skip=0&limit=100`;
      return new Promise((resolve, reject) => {
        axios.get(url, { headers: { token: provider.token } }).then(
          (res) => {
            if (res.data.code === 201) {
              resolve(res.data.data);
              vm.agentGroupReports =
                (res.data.data && res.data.data.content) || [];
              vm.agentGroupDoc = res.data.data.agentGroupDoc;
              vm.agentMemberCount = res.data.data.countContent;
              vm.isAgentGroupMember = true;

              if (vm.agentGroupReports.length > 0) {
                vm.currentUser = vm.agentGroupReports.filter(
                  (a) => a.userId === vm.userId
                )[0];
                vm.userKPI.esitmatedEarn = vm.currentUser.succeedCommissionFee;
                vm.userKPI.actualEarn =
                  vm.currentUser.actualSucceedCommissionFee;

                let estimateShareCommissionFee = 0;
                let actualShareCommissionFee = 0;
                vm.agentGroupReports.map((a) => {
                  if (a.userId !== vm.userId) {
                    estimateShareCommissionFee += a.shareCommissionFee;
                    actualShareCommissionFee += a.actualShareCommissionFee;
                  }
                  // for group reports
                  if (vm.chartData.labels.length < vm.agentMemberCount) {
                    vm.chartData.labels.push(a.agentDoc.name);
                    vm.chartData.datasets[0].data.push(a.totalPrice);
                    vm.chartData.datasets[1].data.push(a.totalCommissionFee);
                    vm.chartData.datasets[2].data.push(a.succeedCommissionFee);
                    vm.chartData.datasets[3].data.push(a.shareCommissionFee);
                  }
                });
                // for the user
                if (vm.agentGroupDoc.isShareGroup) {
                  vm.userKPI.esitmatedEarn +=
                    estimateShareCommissionFee / (vm.agentMemberCount - 1);
                  vm.userKPI.actualEarn +=
                    actualShareCommissionFee / (vm.agentMemberCount - 1);
                }
                vm.barChartData.datasets[0].data.push(vm.userKPI.esitmatedEarn);
                vm.barChartData.datasets[1].data.push(vm.userKPI.actualEarn);
              }
            }else {
              vm.isAgentGroupMember = false;
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    }, 300),
    fetchAgentGroupSummarybyId: _.debounce(function (userId) {
      let vm = this;
      // vm.fetchAgentGroupReportsbyId({ userId: userId });
      let url = provider.baseURL + `/agentGroupSummary/byId?userId=${userId}`;
      return new Promise((resolve, reject) => {
        axios.get(url, { headers: { token: provider.token } }).then(
          (res) => {
            if (res.data.code === 201) {
              resolve(res.data.data);
              vm.agentGroupSummary = (res.data && res.data.data) || {};
              vm.barChartSummaryData.datasets[0].data.push(
                vm.agentGroupSummary.sumTotalPrice
              );
              vm.barChartSummaryData.datasets[1].data.push(
                vm.agentGroupSummary.sumTotalCommissionFee
              );
              vm.barChartSummaryData.datasets[2].data.push(
                vm.agentGroupSummary.sumSucceedCommissionFee
              );
              vm.barChartSummaryData.datasets[3].data.push(
                vm.agentGroupSummary.sumShareCommissionFee
              );
              vm.barChartSummaryData.datasets[4].data.push(
                vm.agentGroupSummary.sumTotalDepositPrice
              );
              vm.barChartSummaryData.datasets[5].data.push(
                vm.agentGroupSummary.sumTotalActualPrice
              );
              vm.barChartSummaryData.datasets[6].data.push(
                vm.agentGroupSummary.sumActualTotalCommissionFee
              );
              vm.barChartSummaryData.datasets[7].data.push(
                vm.agentGroupSummary.sumActualSucceedCommissionFee
              );
              vm.barChartSummaryData.datasets[8].data.push(
                vm.agentGroupSummary.sumActualShareCommissionFee
              );
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    }, 300),
    fetchAgentKPIbyId: _.debounce(function (userId) {
      let vm = this;
      let url = provider.baseURL + `/agentKPI/byId?userId=${userId}`;
      return new Promise((resolve, reject) => {
        axios.get(url, { headers: { token: provider.token } }).then(
          (res) => {
            if (res.data.code === 201) {
              resolve(res.data.data);
              vm.agentKPI = res.data.data;
              vm.isAgent = true;
              vm.pieChartData.datasets[0].data[0] = vm.agentKPI.totalPrice;
              vm.pieChartData.datasets[0].data[1] = vm.agentKPI.totalActualPrice;
              vm.pieChartData.datasets[0].data[2] = vm.agentKPI.totalDepositPrice;
              vm.pieChartData.datasets[0].data[3] = vm.agentKPI.totalCommissionFee;
            }else {
              vm.isAgent = false;
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    }, 300),
  },
  watch: {
    report(val) {
      let vm = this;
      if(val === "Dashboard"){
        vm.dashboard = true;
        vm.personalKPI = true;
        vm.groupKPI = false;
        vm.comingSoon = false;
        vm.fetchAgentKPIbyId(vm.userId);
      }
      else if (val === "Group KPI") {
        vm.groupKPI = true;
        vm.personalKPI = false;
        vm.comingSoon = false;
        vm.fetchAgentGroupReportsbyId(vm.userId);
        vm.fetchAgentGroupSummarybyId(vm.userId);
      }else if (val === "Personal KPI") {
        vm.dashboard = false;
        vm.personalKPI = true;
        vm.groupKPI = false;
        vm.comingSoon = false;
        vm.fetchAgentKPIbyId(vm.userId);
      }else {
        vm.comingSoon = true;
        vm.groupKPI = false;
        vm.personalKPI = false;
        vm.report = val;
      }
    },
  },
  created() {
    let vm = this;
    vm.userId = this.$route.params.userId;
    vm.fetchAgentKPIbyId(vm.userId);
  },
};
</script>
<style scoped>
.noScroll {
  width: 100%;
  margin: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
</style>