<template>
  <bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  ></bar>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      default: () => {}
    },
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 500,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // chartData: {
      //   labels: ["January", "February", "March"],
      //   datasets: [{ 
      //       label: '# of Votes',
      //       data: [12, 19, 3, 5, 2, 3],
      //       backgroundColor: [
      //           'rgba(255, 99, 132, 0.2)',
      //           'rgba(54, 162, 235, 0.2)',
      //           'rgba(255, 206, 86, 0.2)',
      //           'rgba(75, 192, 192, 0.2)',
      //           'rgba(153, 102, 255, 0.2)',
      //           'rgba(255, 159, 64, 0.2)'
      //       ],
      //       borderColor: [
      //           'rgba(255, 99, 132, 1)',
      //           'rgba(54, 162, 235, 1)',
      //           'rgba(255, 206, 86, 1)',
      //           'rgba(75, 192, 192, 1)',
      //           'rgba(153, 102, 255, 1)',
      //           'rgba(255, 159, 64, 1)'
      //       ],
      //       borderWidth: 1
      //    }],
      // },
      chartOptions: {
        responsive: true,
      }
    };
  },
};
</script>