<template>
  <div class="noScroll">
    <v-row align="center" justify="center">
      <v-col cols="11" md="8" style="text-align: justify">
        <h1>Reports for {{ countryDoc.name }}</h1>
        <h2>Property Dashboard</h2>
        <v-sheet color="lighten-4" class="pa-0" v-if="!isDashboard">
          <v-skeleton-loader
            class="mx-auto"
            max-width="500"
            type="card"
          ></v-skeleton-loader>
        </v-sheet>
        <pie-chart v-if="isDashboard" :chartData="pieChartData"></pie-chart>
        <div></div>
        <br />
        <h2>Property Comparison</h2>
        <v-sheet color="lighten-4" class="pa-0" v-if="!isComparison">
          <v-skeleton-loader
            class="mx-auto"
            max-width="500"
            type="card"
          ></v-skeleton-loader>
        </v-sheet>
        <bar-chart v-if="isComparison" :chartData="barChartData"></bar-chart>
        <br />
        <h2>Top 10 Property By Locations</h2>
        <v-sheet color="lighten-4" class="pa-0" v-if="!isTopten">
          <v-skeleton-loader
            class="mx-auto"
            max-width="500"
            type="card"
          ></v-skeleton-loader>
        </v-sheet>
        <LineChartGenerator
            :chart-options="chartOptions"
            :chart-data="lineChartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
            v-if="isTopten"
          />
        <br />
        <h2>Apply Loan By Property Type</h2>
        <v-sheet color="lighten-4" class="pa-0" v-if="!isApplyLoan">
          <v-skeleton-loader
            class="mx-auto"
            max-width="500"
            type="card"
          ></v-skeleton-loader>
        </v-sheet>
        <LineChartGenerator
            :chart-options="chartOptions"
            :chart-data="applyLoanLineChartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
            v-if="isApplyLoan"
          />
        <p v-if="isApplyLoan">Total Apply Loan Value: {{currencySymbol}}{{totalApplyLoanAmount | formatNumberToKandM}}</p>
        <br />
        <h2>Property Investment</h2>
        <v-sheet color="lighten-4" class="pa-0" v-if="!isPropertyInvestment">
          <v-skeleton-loader
            class="mx-auto"
            max-width="500"
            type="card"
          ></v-skeleton-loader>
        </v-sheet>
        <LineChartGenerator
            :chart-options="chartOptions"
            :chart-data="investmentChartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
            v-if="isPropertyInvestment"
          />
        <p v-if="isPropertyInvestment">Total Investment Value: {{currencySymbol}}{{totalInvestment | formatNumberToKandM}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MainMixin from "@/mixins/mainMixin";
import _ from "lodash";
import BarChart from "../../components/barChart.vue";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import { provider } from "../../service/provider";
import axios from "axios";
import PieChart from "../../components/pieChart.vue";
import { countyMobileList } from "@/libs/countryMobileWithFlag";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "webviewGeneralReports",
  mixins: [MainMixin],
  components: { PieChart, BarChart, LineChartGenerator },
  mounted() {
    this.$jQuery("body").off();
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      chartId: "line-chart",
      datasetIdKey: "label",
      width: 400,
      height: 400,
      cssClasses: "",
      styles: {},
      plugins: [],
      barChartData: {
        labels: ["Property Comparison"],
        datasets: [
          {
            label: "Land",
            data: [0],
            backgroundColor: ["rgba(245, 39, 39, 0.2)"],
            borderColor: ["rgba(245, 39, 39, 1)"],
            borderWidth: 1,
          },
          {
            label: "House",
            data: [0],
            backgroundColor: ["rgba(245, 148, 39, 0.2)"],
            borderColor: ["rgba(245, 148, 39, 1)"],
            borderWidth: 1,
          },
          {
            label: "Room",
            data: [0],
            backgroundColor: ["rgba(245, 238, 39, 0.2)"],
            borderColor: ["rgba(245, 238, 39, 1)"],
            borderWidth: 1,
          },
          {
            label: "Condo",
            data: [0],
            backgroundColor: ["rgba(170, 245, 39, 0.2)"],
            borderColor: ["rgba(170, 245, 39, 1)"],
            borderWidth: 1,
          },
          {
            label: "Apartment",
            data: [0],
            backgroundColor: ["rgba(106, 245, 39, 0.2)"],
            borderColor: ["rgba(106, 245, 39, 1)"],
            borderWidth: 1,
          },
          {
            label: "Farm Land",
            data: [0],
            backgroundColor: ["rgba(39, 245, 196, 0.2)"],
            borderColor: ["rgba(39, 245, 196, 1)"],
            borderWidth: 1,
          },
          {
            label: "Room For Rent",
            data: [0],
            backgroundColor: ["rgba(39, 153, 245, 0.2)"],
            borderColor: ["rgba(39, 153, 245, 1)"],
            borderWidth: 1,
          },
          {
            label: "Twin Villa",
            data: [0],
            backgroundColor: ["rgba(39, 50, 245, 0.2)"],
            borderColor: ["rgba(39, 50, 245, 1)"],
            borderWidth: 1,
          },
          {
            label: "Queen Villa",
            data: [0],
            backgroundColor: ["rgba(131, 39, 245, 0.2)"],
            borderColor: ["rgba(131, 39, 245, 1)"],
            borderWidth: 1,
          },
          {
            label: "Shop House",
            data: [0],
            backgroundColor: ["rgba(213, 39, 245, 0.2)"],
            borderColor: ["rgba(213, 39, 245, 1)"],
            borderWidth: 1,
          },
          {
            label: "Office",
            data: [0],
            backgroundColor: ["rgba(245, 39, 170, 0.2)"],
            borderColor: ["rgba(245, 39, 170, 1)"],
            borderWidth: 1,
          },
          {
            label: "Warehouse",
            data: [0],
            backgroundColor: ["rgba(245, 39, 93,  0.2)"],
            borderColor: ["rgba(245, 39, 93,  1)"],
            borderWidth: 1,
          },
        ],
      },
      pieChartData: {
        labels: [
          "Property Listing",
          "Real Estate Companies",
          "Real Estate Agents",
          "Free Members",
        ],
        datasets: [
          {
            backgroundColor: ["#482ff7", "#2d6cdf", "#46c3db", "#f3f169"],
            data: [],
          },
        ],
      },
      countryDoc: {},
      generalReports: {},
      totalProperty: 0,
      propertyByCountry: [],
      userData: [],
      isDashboard: false,
      isComparison: false,
      propertyGroupByLocation: [],
      lineChartData: {
        labels: [],
        datasets: [
          {
            label: "Property",
            backgroundColor: "#581b98",
            data: [],
          },
        ],
      },
      applyLoanLineChartData: {
        labels: [],
        datasets: [
          {
            label: "Apply Loan",
            backgroundColor: "#2d6cdf",
            data: [],
          },
        ],
      },
      isTopten: false,
      isApplyLoan: false,
      applyLoanByCountry: [],
      totalApplyLoanAmount: 0,
      currencySymbol: "$",
      isPropertyInvestment: false,
      investmentChartData: {
        labels: [],
        datasets: [
          {
            label: "Property Investment",
            backgroundColor: "#2d698f",
            data: [],
          },
        ],
      },
      totalInvestment: 0
    };
  },
  methods: {
    fetchGeneralReports: _.debounce(function (countryId) {
      let vm = this;
      let url = provider.baseURL + `/report/general?countryId=${countryId}`;
      return new Promise((resolve, reject) => {
        axios.get(url, { headers: { token: provider.token } }).then(
          (res) => {
            if (res.data.code === 201) {
              resolve(res.data.data);
              // vm.generalReports = res.data.data;
              vm.totalProperty = res.data.data.totalProperty;
              vm.propertyByCountry = res.data.data.propertyByCountry;
              vm.userData = res.data.data.userData;
              vm.totalAgency = vm.userData[
                  vm.userData.findIndex(
                    (d) => d._id === "Agency"
                  )
                ].number;
              vm.totalAgent = vm.userData[
                  vm.userData.findIndex((d) => d._id === "Agent")
                ].number;
              vm.totalMember = vm.userData[
                  vm.userData.findIndex(
                    (d) => d._id === "Member"
                  )
                ].number;
              // property dashboard
              vm.pieChartData.labels[0] = ("Property Listing (" + vm.formatNumber(vm.totalProperty) + ")");
              vm.pieChartData.datasets[0].data[0] = vm.totalProperty;

              vm.pieChartData.labels[1] = ("Real Estate Companies (" + vm.formatNumber(vm.totalAgency) + ")");
              vm.pieChartData.datasets[0].data[1] = vm.totalAgency;
              
              vm.pieChartData.labels[2] = ("Real Estate Agents (" + vm.formatNumber(vm.totalAgent) + ")");
              vm.pieChartData.datasets[0].data[2] = vm.totalAgent;                
              
              vm.pieChartData.labels[3] = ("Free Members (" + vm.formatNumber(vm.totalMember) + ")");
              vm.pieChartData.datasets[0].data[3] = vm.totalMember;
              
              vm.isDashboard = true;

              // property comparison
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "land"
                ) >= 0
              ) {
                vm.totalLand = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "land"
                    )
                  ].number;
                vm.barChartData.datasets[0].data[0] = vm.totalLand;
                vm.barChartData.datasets[0].label = "Land (" + vm.formatNumber(vm.totalLand) + ")";
              }
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "house"
                ) >= 0
              ) {
                vm.totalHouse = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "house"
                    )
                  ].number;
                vm.barChartData.datasets[1].data[0] = vm.totalHouse;
                vm.barChartData.datasets[1].label = "House (" + vm.formatNumber(vm.totalHouse) + ")";
              }
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "room"
                ) >= 0
              ) {
                vm.totalRoom = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "room"
                    )
                  ].number;
                vm.barChartData.datasets[2].data[0] = vm.totalRoom;
                vm.barChartData.datasets[2].label = "Room (" + vm.formatNumber(vm.totalRoom) + ")";
              }
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "condo"
                ) >= 0
              ) {
                vm.totalCondo = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "condo"
                    )
                  ].number;
                vm.barChartData.datasets[3].data[0] = vm.totalCondo;
                vm.barChartData.datasets[3].label = "Condo (" + vm.formatNumber(vm.totalCondo) + ")";
              }
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "apartment"
                ) >= 0
              ) {
                let totalApartment = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "apartment"
                    )
                  ].number;
                vm.barChartData.datasets[4].data[0] = totalApartment;
                vm.barChartData.datasets[4].label = "Apartment (" + vm.formatNumber(totalApartment) + ")";
              }
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "farmLand"
                ) >= 0
              ) {
                let totalFarmLand = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "farmLand"
                    )
                  ].number;
                vm.barChartData.datasets[5].data[0] = totalFarmLand;
                vm.barChartData.datasets[5].label = "Farm Land (" + vm.formatNumber(totalFarmLand) + ")";
              }
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "roomForRent"
                ) >= 0
              ) {
                let totalRoomForRent = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "roomForRent"
                    )
                  ].number;
                vm.barChartData.datasets[6].data[0] = totalRoomForRent;
                vm.barChartData.datasets[6].label = "Room For Rent (" + vm.formatNumber(totalRoomForRent) + ")";
              }
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "twinVilla"
                ) >= 0
              ) {
                let totatlTwinVilla = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "twinVilla"
                    )
                  ].number;
                vm.barChartData.datasets[7].data[0] = totatlTwinVilla;
                vm.barChartData.datasets[7].label = "Twin Villa (" + vm.formatNumber(totatlTwinVilla) + ")";
              }
              
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "queenVilla"
                ) >= 0
              ) {
                let totalQueenVilla = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "queenVilla"
                    )
                  ].number;
                vm.barChartData.datasets[8].data[0] = totalQueenVilla;
                vm.barChartData.datasets[8].label = "Queen Villa (" + vm.formatNumber(totalQueenVilla) + ")";
              }
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "shopHouse"
                ) >= 0
              ) {
                let totalShopHouse = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "shopHouse"
                    )
                  ].number;
                vm.barChartData.datasets[9].data[0] = totalShopHouse;
                vm.barChartData.datasets[9].label = "Shop House (" + vm.formatNumber(totalShopHouse) + ")";
                  
              }
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "office"
                ) >= 0
              ) {
                let totalOffice = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "office"
                    )
                  ].number;
                vm.barChartData.datasets[10].data[0] = totalOffice;
                vm.barChartData.datasets[10].label = "Office (" + vm.formatNumber(totalOffice) + ")";
              }
              if (
                vm.propertyByCountry.findIndex(
                  (d) => d._id === "warehouse"
                ) >= 0
              ) {
                let totalWarehouse = vm.propertyByCountry[
                    vm.propertyByCountry.findIndex(
                      (d) => d._id === "warehouse"
                    )
                  ].number;
                vm.barChartData.datasets[11].data[0] = totalWarehouse;
                vm.barChartData.datasets[11].label = "Warehouse (" + vm.formatNumber(totalWarehouse) + ")";
              }
              vm.isComparison = true;
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    }, 300),
    fetchPropertyGroupByLocation: _.debounce(function (countryId) {
      let vm = this;
      let url = provider.baseURL + `/property/groupByLocation?countryId=${countryId}`;
      return new Promise((resolve, reject) => {
        axios.get(url, { headers: { token: provider.token } }).then(
          (res) => {
            if (res.data.code === 201) {
              resolve(res.data.data);
              let sortedArray = res.data.data.sort((a, b) => b.number - a.number).slice(0,10);
							vm.propertyGroupByLocation = sortedArray || [];
              vm.propertyGroupByLocation.map(d => {
                vm.lineChartData.labels.push(d.locationDoc.name + " (" + vm.formatNumber(d.number) + ")");
                vm.lineChartData.datasets[0].data.push(d.number);
              })
              vm.isTopten = true;
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    }, 300),
    fetchApplyLoanReport: _.debounce(function (countryId) {
      let vm = this;
      let url = provider.baseURL + `/applyLoanReport/fetch?countryId=${countryId}`;
      return new Promise((resolve, reject) => {
        axios.get(url, { headers: { token: provider.token } }).then(
          (res) => {
            if (res.data.code === 201) {
              resolve(res.data.data);
              let sortedArray = res.data.data.propertyList.slice(0,10);
							vm.applyLoanByCountry = sortedArray || [];
              let nullValue = 0;
              vm.applyLoanByCountry.map(d => {
                if(d._id !== null){
                  vm.applyLoanLineChartData.labels.push(d._id + " (" + vm.formatNumber(d.total) + ")");
                  vm.applyLoanLineChartData.datasets[0].data.push(d.total);
                }else{
                  nullValue = d.total;
                }
              })
              vm.totalApplyLoanAmount = res.data.data.total - nullValue;
              vm.isApplyLoan = true;
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    }, 300),
    fetchPropertySupply: _.debounce(function (countryId) {
      let vm = this;
      let url = provider.baseURL + `/report/propertySupply?countryId=${countryId}`;
      return new Promise((resolve, reject) => {
        axios.get(url, { headers: { token: provider.token } }).then(
          (res) => {
            if (res.data.code === 201) {
              resolve(res.data.data);
              console.log(res.data.data)
              vm.propertyInvestment = res.data.data.propertySupplyByType;
              vm.propertyInvestment.map(d => {
                vm.investmentChartData.labels.push(d._id + " (" + vm.formatNumberToKandM(d.totalSupply) + ")");
                vm.investmentChartData.datasets[0].data.push(d.totalSupply);
              });
              vm.totalInvestment = res.data.data.totalSupply;
              vm.isPropertyInvestment = true;
            }else {
              vm.isAgent = false;
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    }, 300),
  },
  watch: {
    report(val) {
      let vm = this;
      if (val === "Group KPI") {
        vm.groupKPI = true;
        vm.personalKPI = false;
        vm.comingSoon = false;
        vm.fetchAgentGroupReportsbyId(vm.userId);
        vm.fetchAgentGroupSummarybyId(vm.userId);
      } else if (val === "Personal KPI") {
        vm.personalKPI = true;
        vm.groupKPI = false;
        vm.comingSoon = false;
        vm.fetchAgentKPIbyId(vm.userId);
      } else {
        vm.comingSoon = true;
        vm.groupKPI = false;
        vm.personalKPI = false;
        vm.report = val;
      }
    },
  },
  created() {
    let vm = this;
    vm.countryCode = this.$route.params.countryCode;
    vm.countryDoc = countyMobileList.find((o) => {
      return o.value === vm.countryCode || o.number === vm.countryCode;
    });
    vm.currencySymbol = vm.getCurrencySymbol(vm.countryCode);
    vm.fetchGeneralReports(vm.countryDoc.number);
    vm.fetchPropertyGroupByLocation(vm.countryDoc.number);
    vm.fetchApplyLoanReport(vm.countryDoc.number);
    vm.fetchPropertySupply(vm.countryDoc.number);
  },
};
</script>

<style scoped>
.noScroll {
  width: 100%;
  margin: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
</style>